@charset 'UTF-8';

.c-btn {
  display: block;
  text-align: center;
  span {
    display: inline-block;
  }
}

.js_bg_lazy_first, .js_bg_lazy{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#wrap{
  margin-top: 180px;
  @media (max-width: 927px) {
    margin-top: 217px;
  }
  @media (max-width: 877px) {
    margin-top: 263px;
  }
  @include SP{
    margin-top: 60px;
  }
}

.c-sectitle-page{
  padding: 0 60px;
  @include max-screen(1150px) {
    padding-left: 18px;
    padding-right: 18px;
  }
  @include SP{
    padding: 0;
  }
  .c-sectitle-page-bg{
    position: relative;
    height: 220px;
    @include SP{
      height: 130px;
    }
    &__cnt{
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      .ttl-page-en{
        @include ffNunito;
        @include font(34, 1, '', bold);
        margin-bottom: 10px;
      }
      .ttl-page-jp{
        @include font(16, 1);
      }
    }
  }
}

.c-breadcrumb {
  padding: 12px 60px 0;
  @include font(13, 29px, 0);
  color: #8E8E8E;
  @include max-screen(1150px) {
    padding-left: 18px;
    padding-right: 18px;
  }
  @include SP{
    @include font(11, 24px);
    padding: 0 18px;
    margin-top: 8px;
  }
  li{
    display: inline;
    &:after{
      content: ">";
      margin-left: 5px;
      @include SP {
        margin-left: 3px;
      }
    }
    &:last-child{
      &:after{
        content: none;
      }
    }
    a{
      color: #8E8E8E;
      text-decoration: underline;
    }
  }
}

.swiper-container{
  .swiper-pagination{
    bottom: auto !important;
    top: 0;
    .swiper-pagination-bullet{
      width: 9px;
      height: 9px;
      opacity: 1;
      background-color: #E6E6E6;
      margin: 0 2px !important;
      &.swiper-pagination-bullet-active{
        background-color: #B9DB35;
      }
    }
  }
  .swiper-button-next, .swiper-button-prev{
    top: 0;
    background: none;
    width: auto;
    margin-top: 0;
    height: auto;
    @include ffNunito;
    @include font(14, 19px, .075em);
    padding-bottom: 3px;
    &:before{
      content: "";
      height: 2px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #252B2A;
      position: absolute;
    }
  }
  .swiper-button-next{
    padding-right: 20px;
    right: 0;
    @include SP{
      right: 16px;
    }
    span{
      position: absolute;
      width: 9px;
      height: 7px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='7.413' viewBox='0 0 9 7.413'%3E%3Cdefs%3E%3Cstyle%3E.a,.b%7Bfill:none;stroke:%23252b2a;stroke-linecap:round;%7D.a%7Bstroke-linejoin:round;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(801.58 1469.499) rotate(180)'%3E%3Cpath class='a' d='M1244.605,1461.643l2.8,3.059-2.8,2.941' transform='translate(-446.328 1.149)'/%3E%3Cpath class='b' d='M1245.446,1466.283h-7.366' transform='translate(-445 -0.479)'/%3E%3C/g%3E%3C/svg%3E");
      // background-size: 100% 100%;
      top: 0;
      bottom: 3px;
      right: 0;
      margin: auto;
      display: block;
      transform: rotate(180deg);
      &:before{
        content: "";
        left: 0;
        right: 100%;
        position: absolute;
        background-color: #fff;
        bottom: 0;
        top: 0;
      }
    }
    @include HOVER{
      &:hover{
        &:before{
          animation-name: next-arrow-hover;
          animation-duration: 1.3s;
        }
        span{
          &:before{
            animation-name: arrow-hover-span;
            animation-duration: 1.3s;
          }
        }
      }
    }
  }
  .swiper-button-prev{
    padding-left: 20px;
    left: 0;
    @include SP{
      left: 16px;
    }
    span{
      position: absolute;
      width: 9px;
      height: 7px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='7.413' viewBox='0 0 9 7.413'%3E%3Cdefs%3E%3Cstyle%3E.a,.b%7Bfill:none;stroke:%23252b2a;stroke-linecap:round;%7D.a%7Bstroke-linejoin:round;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(801.58 1469.499) rotate(180)'%3E%3Cpath class='a' d='M1244.605,1461.643l2.8,3.059-2.8,2.941' transform='translate(-446.328 1.149)'/%3E%3Cpath class='b' d='M1245.446,1466.283h-7.366' transform='translate(-445 -0.479)'/%3E%3C/g%3E%3C/svg%3E");
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: block;
      &:before{
        content: "";
        left: 100%;
        right: 0;
        position: absolute;
        background-color: #fff;
        bottom: 0;
        top: 0;
      }
    }
    @include HOVER{
      &:hover{
        &:before{
          animation-name: prev-arrow-hover;
          animation-duration: 1.3s;
        }
        span{
          &:before{
            animation-name: arrow-hover-span;
            animation-duration: 1.3s;
          }
        }
      }
    }
  }
}

.js_bg_lazy_first{
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.c-btn-white{
  display: block;
  width: 264px;
  margin: auto;
  text-align: center;
  position: relative;
  border: 1px solid #252B2A;
  @include font(14, 19px, .075em);
  max-width: 100%;
  @include IE{
    padding-top: 5px;
  }
  &.ffNu{
    @include ffNunito;
  }
  &.w300{
    width: 300px;
  }
  &:before{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #252B2A;
    transform: scale(1, 0);
    transform-origin: left top;
    transition: transform .3s;
  }
  span{
    position: relative;
    display: block;
    padding: 18px 0;
    &:after{
      content: "";
      width: 8px;
      height: 7px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='7.387' viewBox='0 0 8 7.387'%3E%3Cdefs%3E%3Cstyle%3E.a,.b%7Bfill:none;stroke:%23252b2a;stroke-linecap:round;%7D.a%7Bstroke-linejoin:round;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-793.08 -1462.078)'%3E%3Cpath class='a' d='M1244.948,1461.622l2,3-2,3' transform='translate(-446.368 1.149)'/%3E%3Cpath class='b' d='M1245.58,1466.5h-7' transform='translate(-445 -0.732)'/%3E%3C/g%3E%3C/svg%3E");
      margin: auto;
      @include trans;
    }
  }
  @include HOVER{
    &:hover{
      opacity: 1;
      color: #fff !important;
      &:before{
        transform: scale(1, 1);
        transform-origin: left bottom;
      }
      span{
        &:after{
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='7.387' viewBox='0 0 8 7.387'%3E%3Cdefs%3E%3Cstyle%3E.a,.b%7Bfill:none;stroke:%23fff;stroke-linecap:round;%7D.a%7Bstroke-linejoin:round;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-793.08 -1462.078)'%3E%3Cpath class='a' d='M1244.948,1461.622l2,3-2,3' transform='translate(-446.368 1.149)'/%3E%3Cpath class='b' d='M1245.58,1466.5h-7' transform='translate(-445 -0.732)'/%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
  }
  &.down{
    span{
      &:after{
        transform: rotate(90deg);
      }
    }
  }
}

@keyframes next-arrow-hover {
  0%  { left: 0;right: 0; }
  25% { left: 100%;right: 0; }
  50% { left: 0;right: 100%; }
  100%{ left: 0;right: 0; }
}

@keyframes arrow-hover-span {
  0%  { left: 100%;right: 0; }
  50% { left: 0;right: 0; }
  100%{ left: 0;right: 100%; }
}

@keyframes prev-arrow-hover {
  0%  { left: 0;right: 0; }
  25% { left: 0;right: 100%; }
  50% { left: 100%;right: 0; }
  100%{ left: 0;right: 0; }
}

  .pagingDt {
    @include clearfix();
    position: relative;
    z-index: 0;
    padding: 44px 0 50px;
    width: 100%;
    margin: 95px auto 148px;
    width: calc(100% - 8.8%);
    background-color: #F0F0F0;
    @include SP() {
      margin: 25px 0 62px;
      @include font-size(14);
      line-height: 24px;
      padding: 46px 0;
      margin: 50px 0 62px;
      width: 100%;
    }
    // &:before{
    //   content: "";
    //   background: #F0F0F0;
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   right: 4.4%;
    //   left: 4.4%;
    //   z-index: -1;
    //   @include SP() {
    //     left: 0;
    //     right: 0;
    //   }
    // }
    .container{
      @include clearfix();
    }
  }
  .pagingDt .pageEn{
    position: relative;
    padding-left: 21px;
    @include ffNunito;
    letter-spacing: 0.075em;
    margin-bottom: 12px;
    display: block;
    &:before{
      background-image: url(../img/voice/ico_arrow_pr.png);
      background-size: 100% 100%;
      content: "";
      width: 9px;
      height: 8px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transform: rotate(180deg);
    }
    &:after{
      content: "";
      width: 9px;
      height: 8px;
      position: absolute;
      background-color: #F0F0F0;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transform: translateX(101%);
    }
  }
  .pagingDt p{
    margin-right: 0;
  }
  .pagingDt{
    .prev,.next{
      width: 31.3%;
      padding: 0;
      a{
        text-decoration: none;
        @include font(14);
        line-height: 22px;
        position: relative;
        padding-bottom: 22px;
        overflow: hidden;
        @include SP() {
          padding-bottom: 15px;
        }
        &:before, &:after {
          content: "";
          width: 100%;
          height: 2px;
          background-color: #252B2A;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        &:after {
          transition: transform 0s;
        }
        @include HOVER {
          &:hover {
            opacity: 1;
            &:before {
              transform: translateX(100%);
              transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, -webkit-transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
            }
            &:after {
              transform: translateX(0%);
              transition: transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s, -webkit-transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
            }
            .pageEn::after {
              transform: translateX(101%);
              transition: transform 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, -webkit-transform 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
            }
          }
        }
      }
    }
  }
  .pagingDt .prev {
    float:left;
    @include SP() {
      width: 50%;
      padding-right: 15px;
      margin-bottom: 25px;
    }
    a{
      position: relative;
      display: block;
      &:after {
        transform: translateX(101%);
      }
      .pageEn{
        &:before{
          // transform: translateX(101%);
        }
      }
      @include HOVER {
        &:hover {
          &:before {
            transform: translateX(-100%);
            transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, -webkit-transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
          }
          &:after {
            transform: translateX(0%);
            transition: transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s, -webkit-transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
          }
          .pageEn::after {
            transform: translateX(-101%);
            transition: transform 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, -webkit-transform 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
          }
        }
      }

      @include SP() {
        padding-left: 0;
        background-position: top 9px left 0;

      }
    }
  }
  .pagingDt .next {
    float:right;
    @include SP() {
      width: 50%;
      padding-left: 15px;
      margin-bottom: 25px;
    }
    a{
      position: relative;
      display: block;
      &:after {
        transform: translateX(-101%);
      }
      &:hover{
        @include HOVER {
          background-position: bottom 12px  right 3px;
        }
      }
      @include SP() {
        padding-right: 0;
        background-position: top 9px right 0;
      }
      .pageEn{
        text-align: right;
        padding-right: 21px;
        &:before{
          transform: none;
          left: auto;
          right: 0;
        }
        &:after{
          transform: translateX(-101%);
          left: auto;
          right: 0;
        }
      }
    }
  }
  .pagingDt .list {
    position:absolute;
    left: 50%;
    top: 50%;
    width: 24%;
    margin-left: -12%;
    margin-top: -38px;
    text-align: center;
    padding: 13px 0;
    @include font-size(12);
    letter-spacing: 0.075em;
    font-weight: bold;
    @include ffNunito;
    @include SP() {
      position: relative;
      width: 100%;
      @include transform_c(none);
      left: auto;
      top: auto;
      clear: both;
      padding: 0;
      text-align: center;
      margin: 0 0 20px;
    }
    img{
      max-width: 19px;
      margin: 0 auto 2px;
      display: block;
    }
    a{
      color: #252B2A;
      text-decoration: none;
      display: inline-block;
    }
  }

.box-work-voice{
  width: 560px;
  display: block;
  margin: auto;
  border: 1px solid #9FBC2E;
  max-width: 100%;
  a{
    @include flexbox;
    @include flex-wrap;
  }
  &__img{
    width: 269px;
    @include aspect-ratio(269, 168);
    @include SP{
      width: 163px;
      @include aspect-ratio(163, 130);
      @include IP5{
        width: 130px;
        @include aspect-ratio(130, 130);
      }
    }
  }
  .box-work-voice-cnt{
    width: calc(100% - 269px);
    @include flexbox;
    @include align-items;
    padding: 25px;
    padding-right: 35px;
    position: relative;
    @include trans;
    @include SP{
      width: calc(100% - 163px);
      padding: 18px;
      padding-right: 35px;
      @include IP5{
        width: calc(100% - 130px);
        padding-right: 25px;
      }
    }
    &:before{
      content: "";
      width: 8px;
      height: 6px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 23px;
      margin: auto;
      background-image: url(../img/voice/icon_arrow.png);
      background-size: 100% 100%;
      @include IP5{
        right: 13px;
      }
    }
    .txt-01{
      color: #A3BF35;
      @include font(12, 1, .075em, bold);
      @include ffNunito;
      margin-bottom: 14px;
      @include trans;
    }
    .txt-02{
      @include font(18, 28px, .025em);
      @include trans;
      @include SP{
        @include font(15, 23px);
      }
    }
  }
  @include HOVER{
    &:hover{
      a{
        opacity: 1;
      }
      .box-work-voice-cnt{
        background-color: #F0F0F0;
        .txt-01, .txt-02{
          color: #9FBC2E;
        }
      }
    }
  }
}

.c-list-link{
  @include flexbox;
  @include flex-wrap;
  margin-bottom: 113px;
  @include SP{
    margin-bottom: 79px;
  }
  li{
    width: calc(25% - 27px / 4);
    margin-right: 9px;
    @include PC{
      &:nth-child(4n){
        margin-right: 0;
      }
    }
    @include SP{
      width: calc(50% - 3px);
      margin-right: 6px;
      margin-bottom: 9px;
      &:nth-child(2n){
        margin-right: 0;
      }
    }
    a{
      display: block;
      position: relative;
      .txt-des{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        @include font(20, 44px, .05em);
        color: #FFF;
        @include SP{
          @include font(16, 35px);
        }
      }
    }
  }
}