@charset "UTF-8";
/* =WordPress Core
-------------------------------------------------------------- */

.cmsContent {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  word-wrap: break-word;
  ul,ol {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  ul {
    &[style*="list-style-type: circle;"] {
      li {
        list-style-type: circle;
      }
    }
    &[style*="list-style-type: square;"] {
      li {
        list-style-type: square;
      }
    }
    li {
      list-style: disc;
    }
  }
  ol {
    &[style*="list-style-type: lower-roman;"] {
      li {
        list-style-type: lower-roman;
      }
    }
    &[style*="list-style-type: lower-alpha;"] {
      li {
        list-style-type: lower-alpha;
      }
    }
    &[style*="list-style-type: lower-greek;"] {
      li {
        list-style-type: lower-greek;
      }
    }
    &[style*="list-style-type: upper-alpha;"] {
      li {
        list-style-type: upper-alpha;
      }
    }
    &[style*="list-style-type: upper-roman;"] {
      li {
        list-style-type: upper-roman;
      }
    }
    li {
      list-style-type: decimal;
    }
  }
  iframe {max-width: 100%!important;}
  img{
    max-width:100%;
    height:auto;
    max-height:100%;
    width: auto;
  }
  .alignnone {
    margin: 5px 20px 20px 0;
  }

  .aligncenter,
  div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
  }

  .alignright {
    float:right;
    margin: 5px 0 20px 20px;
  }

  .alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }

  a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }

  a img.alignnone {
    margin: 5px 20px 20px 0;
  }

  a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }

  a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
  }

  .wp-caption.alignnone {
    margin: 5px 20px 20px 0;
  }

  .wp-caption.alignleft {
    margin: 5px 20px 20px 0;
  }

  .wp-caption.alignright {
    margin: 5px 0 20px 20px;
  }

  .wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
  }

  .wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
  }
  h2 {
    margin: 62px 0 38px;
    padding: 19px 60px 18px 32px;
    @include font(22, 40px, 0.05em);
    background-color: #EBEFE0;
    @include SP {
      margin: 60px 0 30px;
      padding: 23px 30px 18px 30px;
      @include font(20, 30px, 0.03em);
    }
  }
  h3 {
    margin: 83px 0 30px;
    @include font(22, 34px, 0.05em);
    position: relative;
    padding-left: 23px;
    @include SP {
      margin: 35px 0 16px;
      @include font(18, 30px);
    }
    &:before {
      content: "";
      width: 11px;
      height: 11px;
      background-color: #9FBC2E;
      position: absolute;
      top: 11px;
      left: 1px;
    }
  }
  h4 {
    margin: 88px 0 16px;
    @include font(20, 34px, 0.05em);
    color: #9FBC2E;
    @include SP {
      margin: 24px 0 19px;
      @include font(18, 30px);
    }
  }
  p {
    margin-bottom: 28px;
    @include SP {
      margin-bottom: 26px;
    }
  }
}
