@mixin trans($time: 0.3s){
  -webkit-transition: all $time ease-in-out;
  -moz-transition: all $time ease-in-out;
  -ms-transition: all $time ease-in-out;
  -o-transition: all $time ease-in-out;
  transition: all $time ease-in-out;
}

@mixin font($size: "", $lh: "", $letter: "", $fw: ""){
  @if ($size != ''){
    @include font-size($size);
  }
  @if ($lh != ''){
    line-height: $lh;
  }
  @if ($letter != ''){
    letter-spacing: $letter;
  }
  @if ($fw != ''){
    font-weight: $fw;
  }
}

@mixin bgimg($url, $size: '', $position: '', $repeat: ''){
  background-image: url($url);

  @if ($size == ''){
    background-size: cover;
  }@else{
    background-size: $size;
  }

  @if ($position == ''){
    background-position: center center;
  }@else{
    background-position: $position;
  }

  @if ($repeat == ''){
    background-repeat: no-repeat;
  }@else{
    background-repeat: $repeat;
  }
}

@mixin IP5 {
  @include max-screen(360px){
    @content;
  }
}

@mixin ANDR {
  @include max-screen(360px){
    @content;
  }
}

@mixin HOVER {
  @include min-screen(1025px){
    @content;
  }
}

@mixin IE{
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin Safari{
  @media not all and (min-resolution:.001dpcm) {
    @content;
  }
}

@mixin FireFox{
  @-moz-document url-prefix() {
    @content;
  }
}
@mixin setwh($w, $h) {
  height: $h;
  width: $w;
}
@mixin transformRotate($rotate) {
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  -o-transform: rotate($rotate);
  transform: rotate($rotate);
}